import React from 'react'
import classNames from 'classnames'
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import styles from 'assets/jss/material-kit-react/views/termsPage.js'

import { makeStyles } from '@material-ui/core/styles'

import 'assets/css/font_awesome/css/all.min.css'

const useStyles = makeStyles(styles)

export default function SearchPage(props) {
  const classes = useStyles()

  return (
    <div>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <GridContainer justifyContent="center">
          <GridItem xs={12} sm={12} md={12}>
            <div className={classes.profile}>
              <div className={classes.name}>
                <h2 className={classes.title}>{'Terms & Conditions'}</h2>
              </div>
            </div>
          </GridItem>
          <div
            className={classes.root}
            dangerouslySetInnerHTML={{
              __html:
                props.data &&
                props.data.contentfulSiteInfo &&
                props.data.contentfulSiteInfo.terms &&
                props.data.contentfulSiteInfo.terms.childMarkdownRemark &&
                props.data.contentfulSiteInfo.terms.childMarkdownRemark.html,
            }}
          />
        </GridContainer>
      </div>
    </div>
  )
}
