import { container, title } from 'assets/jss/material-kit-react.js'

import imagesStyle from 'assets/jss/material-kit-react/imagesStyles.js'

const profilePageStyle = {
  container,
  profile: {
    textAlign: 'center',
    '& img': {
      maxWidth: '160px',
      width: '100%',
      margin: '0',
      transform: 'translate3d(0, -50%, 0)',
    },
  },
  name: {
    marginTop: '-80px',
  },
  ...imagesStyle,
  main: {
    background: '#e8e8e8',
    position: 'relative',
    zIndex: '3',
    paddingBottom: '100px',
  },
  mainRaised: {
    margin: '80px 30px 0px',
    borderRadius: '6px',
    boxShadow:
      '0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)',
  },
  title: {
    ...title,
    marginBottom: '1rem',
    marginTop: '0.25rem',
    minHeight: '32px',
    textDecoration: 'none',
    color: '#ffffff',
  },
  root: {
    paddingLeft: '300px',
    paddingRight: '300px',
    paddingTop: '50px',
  },
}

export default profilePageStyle
